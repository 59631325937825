// here we add all base urls and keys

import { main_color_skin_sister } from "src/utils/constant";
import { update_root_value } from "src/utils/domUtils";

// Base URLS
// export const baseUri = "http://192.168.1.237:4615";
// console.log(
//   process.env.REACT_APP_PROJECT_TYPE,
//   "REACT_APP_PROJECT_TYPEREACT_APP_PROJECT_TYPEREACT_APP_PROJECT_TYPE"
// );

export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const project_url = process.env.REACT_APP_PROJECT_URL;
export const client_url = process.env.REACT_APP_CLIENT_URL;
export const project_type = process.env.REACT_APP_PROJECT_TYPE;
export const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;
export const socketBaseUri = process.env.REACT_APP_API_SOCKET_BASE_URL;
console.log(project_type, "project type environment .......");
console.log(s3baseUrl, "pic show url ");
// export const material_theme_palate = {
//   lighter: "#d4bff2",
//   light: "#ede7f6",
//   main: "#f6bd4b",
//   dark: "#198BA8",
//   darker: "#198BA8",
//   contrastText: "#fff",
// };
export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#1a93a9",
  dark: "#198BA8",
  darker: "#198BA8",
  contrastText: "#fff",
};

if (
  project_name == "basic-dev" ||
  project_name == "dynamite-lifestyle-dev" ||
  project_name == "dynamite-lifestyle-prod" ||
  project_name == "pgi-dev" ||
  project_name == "dev-feature"
) {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "be-me-academy") {
  material_theme_palate.main = "#9F00FF";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#fff"); //c
  update_root_value("--portal-theme-secondary", "#9F00FF50"); //c
  update_root_value("--text-primary-color", "white"); //c
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  update_root_value("--text-color", "black");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "#9F00FF");
  update_root_value("--background-secondary-color", "#9F00FF");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#e1b1ff");
  update_root_value("--sidebars-hover-color", "#e1b1ff");
  update_root_value("--sidebars-background-color", "#ffffff");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "white");
  update_root_value("--popup-text-color", "#9F00FF");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "#fff"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value("--icons-color-allover", "#e1b1ff");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "#fff");
  update_root_value("--button-text-color", "#9F00FF");
  update_root_value("--button-outlined-color", "#fff");
  update_root_value("--button-hover-color", "#fff");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "rgb(56, 54, 54)");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "dd-default") {
  material_theme_palate.main = "rgba(55, 141, 212, 1)";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "rgba(72, 121, 207, 1)"); //c
  update_root_value(
    "--portal-theme-secondary",
    "linear-gradient(90.79deg, rgba(55, 141, 212, 0.19) 6.86%, rgba(55, 141, 212, 0.19) 6.87%, rgba(55, 141, 212, 0.307187) 97.82%, rgba(56, 48, 35, 0.31) 100%)"
  ); //c
  update_root_value("--text-primary-color", "white"); //c
  update_root_value("--text-secondary-color", "white");
  update_root_value("--text-color", "white");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "rgba(18, 24, 38, 1)");
  update_root_value("--background-secondary-color", "#132144");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "white");
  update_root_value("--sidebars-hover-color", "white");
  update_root_value("--sidebars-background-color", "#132144");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#132144");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "white");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value("--icons-color-allover", "rgba(72, 121, 207, 1)");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "rgba(18, 24, 38, 1)");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else {
  //light theme in else
  material_theme_palate.main = "#1a93a9";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#1a93a9");
  update_root_value("--portal-theme-secondary", "rgb(0 171 85 / 8%)");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "black");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#1a93a9");
  update_root_value("--sidebars-hover-color", "#1a93a9");
  update_root_value("--sidebars-background-color", "white");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#fff");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "#1a93a9");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
}

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
